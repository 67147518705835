.blurry {
    filter: blur(5px); 
}

.service-upgrade {
    position: absolute;
    background-color: rgb(125, 147, 155); 
    color: white;
    border-radius: 10px;
    padding: 20px;
    font-size: large;
    left:  30%;
    top: 30%;
}

.score-card-container {
    h3, strong {
        color: $brand-primary;
    }

    ul {
        padding: 10px;
    }

    li {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 10px;

        label {
            flex: 3
        }

        input, select, .sch {
            flex: 1;

        }
    }
}


.criteria-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;

    th {
        background-color: $brand-primary;
        color: white;
        padding: 10px;
    }

    td {
        padding: 10px;
    }

    tr:hover {
        background-color: #f1f1f1;
    }

    .criteria-row {
        display: flex;
        width: 100%;
        .col-title, .col-comment {
        flex: 2; /* Each takes 1/4 of the space */
    }

    .col-score {
        flex: 1; /* Takes 1/2 of the space */
    }
    }
    
}